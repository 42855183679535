.projectDisplay {
    padding: 20px;
  }
  
  .projectsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
    gap: 20px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .projectCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    height: 250px; /* Increased height for project cards */
  }
  
  .projectCard:hover {
    transform: scale(1.05);
  }
  
  .projectThumbnail {
    width: 100%;
    height: 80%; /* Adjusted height to take more space within the card */
    object-fit: cover;
  }
  
  .projectCard h3 {
    padding: 10px;
    margin: 0;
    font-size: 1.5rem;
    text-align: left; /* Left align the project title */
  }
  
  .projectCard p {
    padding: 0 10px 10px; /* Add padding at the bottom for spacing */
    color: #666;
    text-align: left; /* Left align the project introduction text */
    margin: 0; /* Ensure no extra margin */
    font-size: 1rem; /* Font size for the introduction text */
    height: 50px; /* Fixed height for consistency */
    overflow: hidden; /* Hide overflow if text is too long */
    display: -webkit-box; /* For ellipsis */
    display: box; /* Fallback for non-webkit browsers */
    -webkit-box-orient: vertical; 
    box-orient: vertical; /* Standard property (for browsers that support it) */
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    line-clamp: 2; /* Standard property */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
  }
  
  
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75); /* Darker background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure popup is on top */
  }
  
  .popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px; /* Increased max width for larger screens */
    width: 90%; /* Responsive width */
    max-height: 80vh; /* Limit height to 80% of the viewport */
    overflow-y: auto; /* Allow scrolling if content is too long */
    text-align: left; /* Align text to the left */
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Professional box shadow */
  }
  
  .popupCloseIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  .popupImageContainer {
    margin: 20px 0;
    display: flex;
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center items */
  }
  
  .popupImage {
    width: 100%;
    max-height: 400px; /* Limit the height of the image */
    object-fit: contain; /* Keep the aspect ratio */
    border-radius: 10px;
  }
  
  .popupNavigation {
    display: flex;
    justify-content: space-between;
    margin-top: 10px; /* Space above navigation buttons */
    width: 100%; /* Make sure buttons take full width */
  }
  
  .navIcon {
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  .popupDescription {
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
    line-height: 1.5;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .popupContent {
      max-width: 90%; /* Adjust width for small devices */
    }
  
    .projectsGrid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    }
  }
  
  @media (max-width: 480px) {
    .popupContent {
      max-width: 100%; /* Full width for very small screens */
    }
  
    .projectsGrid {
      grid-template-columns: 1fr; /* 1 column on mobile */
    }
  
    .popupCloseIcon {
      font-size: 20px; /* Smaller close icon for mobile */
    }
  }
  